<template>
  <v-container class="my-5">
    <h1 class="text-no-wrap primary--text text-center mb-5 pb-5">
      Registrierung ist im Moment nicht möglich
    </h1>
    <v-img
      src="/img/auth/undraw_registration.svg"
      class="ma-5"
      contain
      position="center center"
      aspect-ratio="16/9"
      max-height="500"
    ></v-img>
    <v-layout row wrap justify-space-between>
      <v-flex
        align-center
        xs10
        sm8
        md6
        class="mr-md-5 mr-xs-0"
        align-self-center
        offset-xs-1
        offset-sm-2
        offset-md-0
      >
        <!-- hier kommt eigentlich das v-img rein -->
      </v-flex>
      <!--
      <v-flex
        xs12
        sm8
        md5
        class="mb-5"
        align-self-center
        offset-sm-2
        offset-md-0
      >
        <v-card flat outlined>
          <v-card-title>
            <h2>Sign Up</h2>
            <h2 class="text--red text-no-wrap secondary">
              Registrierung ist im Moment nicht möglich
            </h2>
          </v-card-title>
          <v-card-text>
            <v-form class="px-3" ref="registerForm">
                <v-text-field
                label="Username"
                v-model="username"
                :rules="usernameRules"
                type="username"
              >
              </v-text-field>
              <v-text-field
                label="Email"
                v-model="email"
                :rules="emailRules"
                type="email"
              >
              </v-text-field>
              <v-select
                ref="sub_model_form"
                :items="subModels"
                :label="subTitle"
                :rules="submodelRules"
                v-model="choosenModel"
              ></v-select>
              <v-text-field
                label="Password"
                v-model="password"
                :rules="passwordRules"
                type="password"
              >
              </v-text-field>
              <v-checkbox
                v-model="tos_check"
                :label="tos_label"
                :rules="[v => !!v || 'Sie müssen den AGBs zustimmen']"
              ></v-checkbox>
              <v-spacer></v-spacer>
              <v-btn color="primary" class="mx-0 mt-3" @click="submitRegisterForm"
                >Sign Up</v-btn
              >
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex> -->
      <v-spacer></v-spacer>
    </v-layout>
    <div class="text-center mt-8">
      <h2 class="">
        Sie können sich aber zu unserem Newsletter anmelden. Drücken sie dafür
        <a href="https://edgefy.net/de#newsletter" target="_blank">hier</a>.
      </h2>
    </div>
    <div class="text-center mt-5">
      <v-btn
        outlined
        color="primary"
        block
        x-large
        href="https://edgefy.net"
        class="mt-5"
      >
        <a href="">Zurück zur Homepage</a>
        <v-icon right>mdi-arrow-right</v-icon>
      </v-btn>
      <v-btn
        outlined
        color="primary"
        block
        x-large
        :to="`/auth/login`"
        class="mt-5"
      >
        <span>LogIn</span>
        <v-icon right>mdi-arrow-right</v-icon>
      </v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Register",
  data() {
    return {
      username: "",
      email: "",
      password: "",
      tos_check: false,
      tos_label: "Akzeptiere unsere AGBs",
      subTitle: "Subscription Model",
      subModels: ["Starter", "Normal", "Pro"],
      choosenModel: "Starter",

      usernameRules: [
        (v) => (v && v.length > 0) || "The username field is required",
      ],
      emailRules: [(v) => (v && v.length > 0) || "The email field is required"],
      passwordRules: [
        (v) =>
          (v && v.length > 8) || "Your Password needs at least 8 characters",
      ],

      registerURL: process.env.VUE_APP_REGISTER_URL,
    };
  },
  methods: {
    async submitRegisterForm() {
      const email = this.$data.email;
      const password = this.$data.password;
      const username = this.$data.username;
      const sub_model = this.$data.choosenModel;

      console.log(email, password, username, sub_model);

      if (this.$refs.registerForm.validate()) {
        let request = await fetch(this.$data.registerURL, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username,
            email,
            password,
            sub_model,
          }),
        });
        console.log(request);
        if (request.status === 200 || request.status === 401) {
          const text = await request.text();
          const data = await JSON.parse(text);
          console.log(data);
          //User ist eingeloggt -> weiterleiten an dashboard
          // this.saveResponseDataToStore(data.user, data.token, data.expiresIn);
          //TODO: Send message you can now LogIn
          this.$router.push("/auth/login");
        } else {
          //some other error
          const text = await request.text();
          const data = await JSON.parse(text);

          //TODO: create error response from the server msg
          console.log(data.msg);
          this.$router.push("/auth/register");
        }
      }
    },
  },
};
</script>

<style>
</style>